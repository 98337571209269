import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import Content, { HTMLContent } from '../components/content'
import * as styles from '../components/all.css'
import * as privacyPolicyStyles from "../components/privacy-policy-page.module.css"

const PrivacyPolicyPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const PageContent = HTMLContent || Content
  
  return (
    <Layout>
      <section className="section section--gradient">
        <div className="header">
        </div>
        <div className="container">
          <div className="privacy intro">
            <h2 className="title">
              {frontmatter.title}
            </h2>
            <p>Latest update: {frontmatter.lastUpdated}</p>
          </div>
        </div>
        <div className="container">
          <div className={privacyPolicyStyles.main_text}>
            <div className="column is-10 is-offset-1">
              <div className="section">
                <PageContent className="content" content={html} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicyPage

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        lastUpdated
      }
    }
  }
`